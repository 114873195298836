.about-members-section{
  padding: 64px 0;
  &.no-upper-padding{
    padding-top: 0;
  }
  .desc {
    width: 70%;
    margin-bottom: 0;
  }

  .hostel-person-wrapper {
    padding: 16px 0 16px 10%;
    overflow-x: scroll;
    position: relative;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .person-cards-wrapper {
    display: inline-flex;
    margin-top: 21px;
    position: relative;
    padding-right: 12px;

    .person-card {
      width: 260px;
      height: 420px;
      background-color: #eee;
      border-radius: 12px;
      box-shadow: rgba(38, 57, 77, 0.2) 0px 1px 15px;
      transition: all 0.3s linear;
      position: relative;
      overflow: hidden;
      margin: 0 12px;
      flex-shrink: 0;

      &:first-child {
        margin-left: 0;
      }

      .person-img {
        width: 100%;
        height: 100%;
        position: relative;
        transition: all 0.3s ease-in-out;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &::before {
          content: "";
          width: 100%;
          height: 40%;
          z-index: 50;
          background-image: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 1) 10%,
            rgba(255, 255, 255, 0) 100%
          );
          transition: all 0.3s ease-in-out;
          position: absolute;
          left: 0;
          bottom: 0;

          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      .person-detail {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
        padding: 16px;
        transition: all 0.3s ease-in-out;

        .person-title {
          font-size: 21px;
          font-weight: 800;
          margin-bottom: 0;
          position: relative;
        }

        .person-desg {
          font-size: 14px;
          margin-bottom: 0;
          margin-top: 4px;
          font-weight: 500;
        }

        a,
        button {
          border: 1px solid #2e3b48;
          padding: 6px 12px;
          color: #2e3b48;
          background-color: transparent;
          transition: all 0.4s ease-in-out;
          font-size: 14px;

          &:hover {
            cursor: pointer;
            color: #ffffff;
            background-color: #2e3b48;
          }
        }
      }

      &:hover {
        transform: scale(1.01);

        .person-img {
          &::before {
            opacity: 0;
          }
        }

        .person-detail {
          opacity: 0;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait){
    padding: 36px 0;

    .desc {
      width: 100%;
    }

    .hostel-person-wrapper {
      padding: 16px 0 16px 4%;
    }

    .person-cards-wrapper {
      display: inline-flex;
      margin-top: 16px;
      position: relative;
      padding-right: 12px;
  
      .person-card {
        width: 260px;
        height: 400px;
  
        .person-detail {
          .person-title {
            font-size: 18px;
          }
        }
      }
    }
  }
}