/**
 * Global Components
 * =================================
 * - Container
 * - Typography
 * - Navigration
 * - Pages > Index Page
 * - 
 */
@import 
 './components/base/container',
 './components/base/typography',
 './components/base/button',
 './components/base/nav',
 './components/base/footer',
 './components/base/tech-icons',
 './components/base/quote-modal';

 @import 
 './components/views/index',
 './components/views/hostel',
 './components/views/contact',
 './components/views/about',
 './components/views/policy';