body {
  background-color: #ffffff;
}

.hero-section {
  width: 100%;
  height: 100vh;
  background-image: url("../img/home/hero1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #0000005c;
    z-index: 100;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 110;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(255, 252, 252, 0) 40%
    );

    position: absolute;
    left: 0;
    top: 0;
  }

  & .title-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 120;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    h1,
    h2 {
      margin: 0;
      color: #ffffff;
      text-transform: uppercase;
    }

    h1 {
      font-size: 10rem;
    }

    h2 {
      color: transparent;
      font-size: 54px;

      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: lighten(#ffffff, 10%);
    }

    p {
      color: #ffffff;
      font-size: 21px;
      margin: 0;
    }

    & .button-wrap {
      margin-top: 42px;

      .btn-1,
      .btn-2 {
        border: 1px solid #ffffff;
        padding: 12px 32px;
        color: #ffffff;
        margin: 0 12px;

        &:hover {
          cursor: pointer;
        }
      }

      .btn-1 {
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #ffffff;
          color: #000000;
        }
      }

      .btn-2 {
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #8631d3;
          border-color: #8631d3;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    & .title-wrapper {
      h1 {
        font-size: 64px;
        text-align: center;
      }

      h2 {
        font-size: 26px;
      }

      p {
        font-size: 14px;
        text-align: center;
      }

      & .button-wrap {
        .btn-1,
        .btn-2 {
          padding: 12px 28px;
          font-size: 14px;
        }
      }
    }
  }
}

.about-section {
  width: 100%;
  overflow: hidden;

  .about-block {
    margin: auto 0;
    padding: 0 160px;
  }

  .about-pic-block {
    height: 620px;
    background-image: url("../img/home/prayer-flags.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 0 !important;

    &.abount-page {
      height: 840px;
      background-image: url("../img/home/hero1.jpg");
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 110;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.9) 35%,
        rgba(255, 255, 255, 0) 100%
      );

      position: absolute;
      left: 0;
      top: 0;
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 42px 4%;

    .row {
      width: 100%;
      margin: 0;
    }

    .about-pic-block {
      display: none;
    }

    .about-block {
      margin: auto 0;
      padding: 0;
    }
  }
}

.experiences-section {
  position: relative;
  overflow: hidden;

  .exp-btns {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 21px;
    position: absolute;
    right: 64px;
    bottom: 116px;
    z-index: 1300;

    &.hide-btn {
      display: none;
    }

    a {
      font-size: 14px;
      color: #ffffff;
      font-weight: 200;
      cursor: pointer;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      background: transparent !important;
      transition: all 0.2s ease-in-out;

      span {
        &.line {
          width: 30px;
          height: 1px;
          background-color: #ffffff;
          transition: all 0.2s ease-in-out;
        }

        &.left {
          margin-right: 6px;
        }
        &.right {
          margin-left: 6px;
        }
      }

      &:hover {
        opacity: 0.8;
        span {
          width: 32px;
        }
      }
    }
  }

  .exp-wrapper {
    position: relative;
    margin-bottom: 200px;

    .exp-image-block {
      width: 80%;
      height: 620px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .ref-block {
      width: 550px;
      height: 550px;
      position: absolute;
      right: 64px;
      top: 220px;
      z-index: 100;
      padding: 32px;

      .service-ico {
        width: 54px;
        height: 54px;
        margin-top: 96px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .title {
        font-size: 32px;
        margin-top: 21px;
        margin-bottom: 8px;
        font-weight: 600;
        color: #ffffff;
      }

      .description {
        font-weight: 200;
        color: #ffffff;
      }

      &.slide-1 {
        background-color: #ad8e6f;
      }
      &.slide-2 {
        background-color: #a25622;
      }
      &.slide-3 {
        background-color: #513928;
      }
      &.slide-4 {
        background-color: #718598;
      }
      &.slide-5 {
        background-color: #0b5aa0;
      }
      &.slide-6 {
        background-color: #332816;
      }
      &.bir-cafe {
        background-image: url("../img/bir/cafe/cafe-2.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .exp-btns {
      width: 340px;
      right: 50%;
      transform: translateX(50%);
      bottom: 42px;
      z-index: 1300;
    }

    .exp-wrapper {
      position: relative;
      margin-bottom: 200px;
  
      .exp-image-block {
        width: 100%;
        height: 620px;
      }
  
      .ref-block {
        width: 340px;
        height: 420px;
        position: absolute;
        right: 50%;
        top: 420px;
        transform: translateX(50%);
        z-index: 100;
        padding: 32px;
  
        .service-ico {
          width: 32px;
          height: 32px;
          margin-top: 46px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
  
        .title {
          font-size: 26px;
          margin-top: 16px;
        }
      }
    }
  }
}

.gallery-section {
  position: relative;
  overflow: hidden;

  .gallery-wrapper {
    position: relative;
    margin-bottom: 350px;

    .gallery-desc {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80%;
      z-index: 100;
      padding: 12px;

      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(255, 252, 252, 0) 40%
      );

      p {
        color: #ffffff;
        font-weight: 600;
        font-size: 26px;
      }
    }

    .gallery-image-block {
      width: 100%;
      height: 620px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .small-img-block {
      width: 320px;
      height: 520px;
      position: absolute;
      right: 120px;
      top: 70%;
      z-index: 110;
      background-color: #eee;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .gallery-wrapper {
      position: relative;
      margin-bottom: 0;

      .gallery-desc {
        p {
          font-size: 14px;
        }
      }

      .gallery-image-block {
        height: 420px;
      }

      .small-img-block {
        display: none;
      }
    }
  }
}

.destinations-section {
  transform: translateY(-200px);
  margin-bottom: -120px;
  overflow: hidden;

  .destination-cards-wrapper {
    display: flex;
    margin-top: 42px;
    padding-bottom: 62px;

    .destination-card {
      width: 300px;
      height: 620px;
      background-color: #eee;
      border-radius: 26px;
      box-shadow: rgba(38, 57, 77, 0.5) 0px 5px 15px;
      transition: all 0.3s linear;
      position: relative;
      overflow: hidden;
      margin: 0 18px;
      flex-shrink: 0;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      .destination-img {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &::before {
          content: "";
          width: 100%;
          height: 40%;
          z-index: 50;
          background-image: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 1) 10%,
            rgba(255, 255, 255, 0) 100%
          );

          position: absolute;
          left: 0;
          bottom: 0;

          border-bottom-left-radius: 26px;
          border-bottom-right-radius: 26px;
        }
      }

      .destination-detail {
        width: 100%;
        height: 120px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
        padding: 16px;

        .city {
          font-size: 32px;
          font-weight: 800;
          margin-bottom: 10px;
          position: relative;

          &.coming-soon {
            &::after {
              content: "coming soon";
              position: absolute;
              font-size: 14px;
              font-weight: 200;
              padding: 4px 8px;
              border: 0.03em solid #2e3b48;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 12px;
            }
          }
        }

        .amn-ico-wrap {
          display: flex;
          align-items: center;

          .amn-ico {
            width: 21px;
            height: 21px;
            margin: 0 8px;

            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }

      &:hover {
        box-shadow: rgb(38, 57, 77, 0.7) 0px 20px 30px -10px;
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    transform: translateY(0);
    margin-bottom: 0;
    padding: 42px 0;

    .container{
      padding: 0;
    }

    .titles{
      padding: 0 4%;
    }

    .destination-cards-wrapper {
      display: flex;
      margin-top: 28px;
      overflow-x: scroll;
      position: relative;
      padding-left: 4%;
      padding-right: 4%;
      padding-bottom: 62px;

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      .destination-card {
        width: 300px;
        height: 580px;
        margin: 0 26px;

        .destination-detail {
          height: 100px;
          
          .city {
            font-size: 26px;
          }

          .amn-ico-wrap {
            display: flex;
            align-items: center;

            .amn-ico {
              width: 21px;
              height: 21px;
              margin: 0 4px;

              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }
          }
        }

        &:hover {
          box-shadow: rgb(38, 57, 77, 0.7) 0px 20px 30px -10px;
          transform: scale(1.05);
        }
      }
    }
  }
}

.explore-section {
  position: relative;
  padding: 44px 0;
  overflow: hidden;

  .himachal-stamp {
    position: absolute;
    top: 0;
    left: 62px;
    opacity: 0.2;
    width: 260px;
    height: 260px;
    z-index: 1000;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .quote {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 72px;

    .big-quote {
      height: 48px;
      width: 48px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    p {
      font-family: sans;
      font-weight: bold;
      font-size: 48px;
      text-align: center;
      line-height: 1.3;
    }

    small {
      margin-left: auto;
      margin-right: 64px;
      color: #317b97;
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .himachal-stamp {
      top: 12px;
      left: 2px;
      width: 160px;
      height: 160px;
    }
    .quote {
      width: 100%;
      margin-top: 42px;
  
      .big-quote {
        height: 32px;
        width: 32px;
      }
  
      p {
        font-family: sans;
        font-weight: bold;
        font-size: 32px;
      }
  
      small {
        margin-left: auto;
        margin-right: 64px;
        color: #317b97;
      }
    }
  }
}

.big-img-section {
  width: 80%;
  margin: 0 auto;
  height: 920px;
  background-image: url("../img/home/big-hero.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  &.home {
    background-image: url("../img/home/big-hero.jpg");
  }

  &.bir {
    background-image: url("../img/bir/bir-hero.jpg");
  }

  @media (max-width: 767px) and (orientation: portrait){
    height: 520px;
  }
}

.testimonial-section {
  padding: 76px 0;
  background-color: rgba(46, 59, 72, 0.05);
  height: 920px;
  overflow: hidden;
  position: relative;
  transition: height 0.7s ease-in-out;

  @media (max-width: 767px) and (orientation: portrait){
    padding: 42px 0;
    height: 1024px;
  }

  .show-shadow {
    width: 100%;
    height: 40%;
    z-index: 90;
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 1) 10%,
      rgba(255, 255, 255, 0) 100%
    );

    position: absolute;
    left: 0;
    bottom: 0;
  }

  .show-more-btn {
    position: absolute;
    left: 50%;
    bottom: 42px;
    z-index: 100;
    transform: translateX(-50%);

    button {
      border: 1px solid #2e3b48;
      padding: 12px 32px;
      color: #2e3b48;
      margin: 0 12px;
      background-color: transparent;
      transition: all 0.4s ease-in-out;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #2e3b48;
      }
    }
  }

  .testimonial-wrapper {
    width: 70%;
    margin: 0 auto;

    .title {
      font-size: 48px;
      font-weight: bold;
      line-height: 1.2;
    }

    .testimonial-card {
      width: 100%;
      min-height: 480px;
      background-color: #fff;
      border-radius: 16px;
      transition: all 0.3s linear;
      position: relative;
      overflow: hidden !important;
      flex-shrink: 0;
      padding: 21px;
      display: flex;
      flex-direction: column;
      margin-bottom: 21px;
      box-shadow: rgb(38, 57, 77, 0.2) 0px 20px 30px -10px;

      &.first-card {
        margin-top: 196px;
      }

      .google {
        background-image: url("../img/home/google.png");
      }
      .ibibo {
        background-image: url("../img/home/goibibo.png");
      }
      .google,
      .ibibo {
        width: 100%;
        height: 60%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 35%;
        transform: translateY(-50%);
        z-index: 1;
        opacity: 0.07;
      }

      .big-quote {
        height: 32px;
        width: 32px;
        margin-bottom: 21px;
        position: relative;
        z-index: 10;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .rank {
        font-weight: bold;
        font-size: 36px;
        margin-bottom: 0;
        position: relative;
        z-index: 10;
      }

      & .testimonial {
        position: relative;
        z-index: 10;
        p {
          font-size: 16px;
        }
      }
      & .testimonial-author {
        position: relative;
        z-index: 10;
        margin-top: auto;
        p {
          margin: 0;
          font-size: 22px;
          font-weight: 400;
        }
      }
    }

    @media (max-width: 767px) and (orientation: portrait) {
      width: 100%;
      padding: 0 4%;

      .testimonial-card {
        width: 100%;
        min-height: 320px;

        &.first-card {
          margin-top: 0;
        }

        .big-quote {
          height: 24px;
          width: 24px;
          margin-bottom: 16px;
        }

        .rank {
          font-size: 26px;
        }

        & .testimonial {
          p {
            font-size: 14px;
          }
        }
        & .testimonial-author {
          p {
            font-size: 21px;
          }
        }
      }
    }
  }
}

.book-now-section {
  width: 100%;
  height: 460px;
  background-image: url("../img/fold.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    background-color: #00000055;
    z-index: 1;
  }

  .container {
    display: flex;
    height: 100%;
  }

  .book-now-wrapper {
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    margin: auto 0;

    .text {
      width: calc(100% - 320px);

      .title {
        font-size: 42px;
        font-weight: bold;
      }

      .title,
      p {
        color: #ffffff;
      }

      p{
        margin: 0;
      }
    }

    .btn {
      width: 320px;
      text-align: right;
      flex-shrink: 0;
      button,
      a {
        border: 1px solid #ffffff;
        padding: 12px 32px;
        color: #ffffff;
        margin: 0 12px;
        background-color: transparent;
        transition: all 0.4s ease-in-out;

        &:hover {
          cursor: pointer;
          color: #2e3b48;
          background-color: #ffffff;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    height: auto;
    padding: 96px 0;

    .book-now-wrapper {
      height: 100%;
      flex-direction: column;

      .text {
        width: 100%;

        .title,
        p {
          color: #ffffff;
        }
      }

      .btn {
        width: 100%;
        text-align: left;
        flex-shrink: 0;
        margin-top: 21px;
        button,
        a {
          margin: 0;
        }
      }
    }
  }
}

.instagram-section {
  padding: 72px 0;
  overflow: visible;

  .container {
    width: 100%;
    margin: 0 auto;
  }

  .follow-wrapper {
    margin: auto 0;
    .logo {
      height: auto;
      width: 142px;
      margin-bottom: 21px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .desc {
      margin-bottom: 32px;
      width: 70%;
    }

    .btn {
      border: 1px solid #2e3b48;
      padding: 12px 32px;
      color: #2e3b48;
      background: transparent;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        border-color: rgba(209,34,67,1);
        background: linear-gradient(266deg, rgba(228,64,95,1) 0%, rgba(228,64,95,1) 38%, rgba(209,34,67,1) 100%);
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 42px 0;

    .container {
      width: 100%;
    }

    .follow-wrapper {
      margin-bottom: 48px;

      .logo {
        height: auto;
        width: 142px;
        margin-bottom: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .desc {
        margin-bottom: 21px;
        width: 100%;
      }

      .btn {
        padding: 12px 16px;
        font-size: 14px;
      }
    }
  }
}

.mobile-view-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  padding-right: 48px;

  .ios-mobile,
  .android-mobile {
    position: relative;

    &-img {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      &::after {
        position: absolute;
        bottom: -16px;
        left: 0;
        text-align: center;
        width: 100%;
        font-size: 10px;
        color: #696969;
      }
    }

    &-img-back {
      position: absolute;
      left: -74px;
      top: 0;
      z-index: 80;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .ios-mobile {
    width: 7.15cm;
    height: 14.67cm;

    &-img-back {
      width: 7.15cm;
      height: 14.67cm;
    }

    &-img-notch {
      width: 2.85cm;
      height: auto;
      position: absolute;
      left: 50%;
      top: 17.5px;
      transform: translateX(-50%);
      z-index: 120;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-img {
      width: 7.15cm;
      height: 14.67cm;
    }

    &-content {
      position: absolute;
      left: 15.5px;
      top: 18px;
      width: 6.34cm;
      height: 13.7cm;
      background-color: #000000;
      z-index: 110;
      border-radius: 31.5px;
      overflow: hidden;

      video {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 0;
    order: 1;
    .ios-mobile {
      margin: 0 auto;

      &-img-back {
        display: none;
      }
    }
  }
}

.title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 16px;
  line-height: 1.3;
  font-family: $font-family-base !important;
  color: #2e3b48;

  @media (max-width: 767px) and (orientation: portrait) {
    font-size: 28px !important;
  }
}
