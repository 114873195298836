.other-hero-section {
  width: 100%;
  height: 460px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;

  &.contact {
    background-image: url("../img/contact.jpeg");
  }

  &.about {
    background-image: url("../img/about.jpeg");
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0000003c;
    z-index: 100;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 110;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(255, 252, 252, 0) 40%
    );

    position: absolute;
    left: 0;
    top: 0;
  }

  & .title-wrapper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 120;

    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 0 10%;

    h1,
    h2 {
      margin: 0;
      color: #ffffff;
      font-family: $font-family-base;
      font-weight: bolder;
    }

    h2 {
      font-size: 42px;

      .active {
        position: relative;

        &::before {
          content: "";
          width: 100%;
          height: 4px;
          background-color: #ffffff;
          position: absolute;
          left: 0;
          bottom: -2px;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    height: 280px;

    .title-wrapper {
      padding: 0 4%;

      h2 {
        font-size: 32px;
      }
    }
  }
}

.contact-section {
  .contact-wrapper {
    padding: 72px 0;
    height: 680px;
    background-image: url("../img/map.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 110;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0) 100%
      );

      position: absolute;
      left: 0;
      top: 0;
    }
    display: flex;
  }

  .contact-details-wrapper {
    position: relative;
    z-index: 150;
    width: 50%;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contact-list {
      margin-top: 32px;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }

        .ico {
          width: 36px;
          height: 36px;
          margin-right: 32px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        .multi-detail {
          display: flex;
          flex-direction: column;
        }

        .detail {
          margin: 0;
          font-size: 24px;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .contact-wrapper {
      padding: 102px 0;
      height: auto;

      &::before {
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 70%,
          rgba(255, 255, 255, 1) 5%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    .contact-details-wrapper {
      width: 90%;
      padding: 0 4%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .contact-list {
        margin-top: 16px;

        li {
          margin-bottom: 21px;

          .ico {
            width: 21px;
            height: 21px;
            margin-right: 16px;
          }

          .multi-detail {
            display: flex;
            flex-direction: column;
          }

          .detail {
            margin: 0;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.destination-contact-section {
  padding-top: 42px;
  .desc {
    width: 70%;
  }

  .hostel-contact-wrapper {
    padding: 0 0 64px 10%;
    overflow-x: scroll;
    position: relative;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .contact-cards-wrapper {
    display: inline-flex;
    margin-top: 12px;
    position: relative;
    padding-right: 12px;

    .contact-card {
      width: 300px;
      height: 520px;
      background-color: #eee;
      border-radius: 16px;
      box-shadow: rgba(38, 57, 77, 0.2) 0px 1px 15px;
      transition: all 0.3s linear;
      position: relative;
      overflow: hidden;
      margin: 0 12px;
      flex-shrink: 0;

      &:first-child {
        margin-left: 0;
      }

      &.coming-soon {
        .contact-img {
          &::before {
            background-image: linear-gradient(
              0deg,
              rgba(255, 255, 255, 1) 2%,
              rgba(255, 255, 255, 1) 2%,
              rgba(255, 255, 255, 0) 100%
            );
          }
        }
      }

      .contact-img {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 50;
          background-image: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 1) 10%,
            rgba(255, 255, 255, 0) 100%
          );

          position: absolute;
          left: 0;
          bottom: 0;

          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }

      .contact-detail {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
        padding: 21px 16px;

        .contact-title {
          font-size: 28px;
          font-weight: 800;
          margin-bottom: 4px;
          margin-top: 8px;
          position: relative;

          &.coming-soon {
            &::after {
              content: "coming soon";
              position: absolute;
              font-size: 14px;
              font-weight: 200;
              padding: 4px 8px;
              border: 0.03em solid #2e3b48;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 12px;
            }
          }
        }

        .contact-desc {
          font-size: 12px;
          margin: 0;
          margin-bottom: 16px;
        }

        .contact-person {
          font-size: 18px;
          margin-bottom: 0;
          margin-top: 4px;
          font-weight: 500;
        }

        a,
        button {
          border: 1px solid #2e3b48;
          padding: 6px 12px;
          color: #2e3b48;
          background-color: transparent;
          transition: all 0.4s ease-in-out;
          font-size: 14px;

          &:hover {
            cursor: pointer;
            color: #ffffff;
            background-color: #2e3b48;
          }
        }
      }

      &:hover {
        transform: scale(1.008);
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding-top: 32px;

    .desc {
      width: 100%;
    }

    .hostel-contact-wrapper {
      padding: 0 0 42px 4%;
      overflow-x: scroll;
      position: relative;
    }

    .contact-cards-wrapper {
      .contact-card {
        width: 320px;
        height: 480px;

        .contact-detail {
          .contact-title {
            font-size: 21px;
          }

          .contact-desc {
            font-size: 12px;
            margin: 0;
            margin-bottom: 16px;
          }

          .contact-person {
            font-size: 16px;
            margin-bottom: 0;
          }
        }

        &:hover {
          transform: scale(1.008);
        }
      }
    }
  }
}
