@use "sass:map";

.nav-container {
  position: absolute;
  z-index: 1000;
  background-color: transparent;
  width: 100vw;
  height: 84px;
  padding: 12px 26px;

  .nav-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    .nav-logo {
      width: 54px;
      height: 54px;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .nav-mobile-icon {
      display: none;
    }

    .nav-links {
      display: flex;
      margin-left: auto;
      color: $white;

      .nav-link{
        font-size: 16px;
        margin: 0 $space;

        &.link-hidden{
          display: none;
        }

        &.link-active{
          position: relative;

          a{
            font-weight: bold;
          }

          &::before{
            content: "";
            width: 100%;
            height: 1px;
            background-color: #FFFFFF;
            position: absolute;
            left: 0;
            bottom: -2px;
          }
        }

        &:last-child{
          margin-right: 0;
        }
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 6px 12px;
    .nav-wrapper {
      height: 48px;

      .nav-logo,
      .nav-links {
        display: none;
      }

      .nav-mobile-icon {
        display: block;
        width: 30px;
        height: 30px;

        img{
          width: 30px;
          height: 30px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}

.quote-link{
  padding: 6px 16px;
  color: $white;
  font-size: 16px;
  border: 1px solid $white;
  margin-left: $space+1;

  @media (max-width: 767px) and (orientation: portrait) {
    margin-left: auto;
    font-size: 12px;
  }
}

.sidebar-container{
  width: 100%;
  height: 100vh;
  background-image: url('../img/sidebar.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateX(-100%);
  display: none;

  @media (max-width: 767px) and (orientation: portrait) {
    display: block;
  }

  &.active{
    transform: translateX(0);
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 110;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 1) 10%,
      rgba(255, 252, 252, 0) 100%
    );

    position: absolute;
    left: 0;
    top: 0;
  }

  .sidebar-wrapper {
    padding: 10px;
    position: relative;
    z-index: 150;

    .nav-mobile-icon {
      display: block;
      width: 30px;
      height: 30px;

      img{
        width: 30px;
        height: 30px;
        object-fit: contain;
        object-position: center;
      }
    }

    .nav-links {
      display: flex;
      margin-left: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #2E3B48;
      margin-top: 48px;

      .nav-link{
        font-size: 28px;
        margin: 0;
        margin-bottom: 32px;
        text-align: center;

        &.link-hidden{
          display: none;
        }

        &.link-active{
          position: relative;

          a{
            font-weight: bold;
          }

          &::before{
            content: "";
            width: 100%;
            height: 3px;
            background-color: #2E3B48;
            position: absolute;
            left: 0;
            bottom: -2px;
          }
        }

        &:last-child{
          margin-right: 0;
        }
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
}